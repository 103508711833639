<form [formGroup]="form" *ngIf="language$ | async as language">
  <div class="row mb-3">
    <div class="col-4 d-flex align-items-center">
      <label [for]="translation.id" class="mb-0">{{language.languageName}}
        <ng-container *ngIf="language.default"><span class="badge badge-warning">Default</span></ng-container>
      </label>
    </div>

    <div class="col-4 d-flex align-items-center">
      <ng-container *ngIf="language.default">
        <button class="btn btn-outline-warning btn-block" (click)="saveForm(true)">
          <ng-container *ngIf="!translating; else translatingTpl">Translate</ng-container>
          <ng-template #translatingTpl>Translating</ng-template>
        </button>
      </ng-container>
    </div>

    <div class="col-4 d-flex align-items-center">
      <button class="btn btn-outline-success btn-block" (click)="saveForm()">
        <ng-container *ngIf="!loading; else loadingTpl">Save</ng-container>
        <ng-template #loadingTpl>Saving</ng-template>
      </button>
    </div>

  </div>
  <div class="form-group">
    <textarea class="form-control" rows="1" formControlName="translation" [id]="translation.id"></textarea>
  </div>
</form>
