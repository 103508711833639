<div class="container pt-5 pb-3">
  <app-header></app-header>
</div>
<div class="container pb-3">
  <app-breadcrumbs [path]="[{link: ['/'], title: 'Home'}, {title: 'Languages'}]"></app-breadcrumbs>
</div>
<div class="container">
  <div class="row">
    <div class="col-3">
      <app-side-nav></app-side-nav>
    </div>
    <div class="col-9">
      <div class="row mb-3">
        <div class="col text-right">
          <button class="btn btn-outline-primary mr-3" (click)="createLanguage()">Create new language</button>
          <button class="btn btn-outline-secondary">Settings</button>
        </div>
      </div>
      <table class="table table-bordered">
        <thead class="">
        <tr>
          <th>#</th>
          <th>Language ID</th>
          <th>Name</th>
          <th>Default</th>
          <th>Enabled</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let language of languages$ | async">
          <td class="align-middle">{{language.languageCode}}</td>
          <td class="align-middle">{{language.languageId}}</td>
          <td class="align-middle">{{language.languageName}}</td>
          <td class="align-middle">
            <span class="badge" [class.badge-success]="language.default" [class.badge-secondary]="!language.default">{{language.default}}</span>
          </td>
          <td class="align-middle">
            <span class="badge" [class.badge-success]="language.enabled" [class.badge-secondary]="!language.enabled">{{language.enabled}}</span>
          </td>
          <td class="align-middle text-right">
            <button class="btn btn-sm btn-outline-secondary mr-3" (click)="editLanguage(language)">Edit</button>
            <button class="btn btn-sm btn-outline-danger mr-3" (click)="deleteLanguage(language)">Delete</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
