import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AbstractUnsubscriber} from '../../core/abstract-unsubscriber';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends AbstractUnsubscriber implements OnInit {
  @Input() path: { link?: string[], title: string }[] = [];

  user$: Observable<any>;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user$ = this.authService.user$;
  }
}
