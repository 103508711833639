import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable()
export abstract class AbstractUnsubscriber implements OnDestroy {
  subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
