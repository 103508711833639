<ng-container>
  <div class="container pt-5 pb-3">
    <app-header></app-header>
  </div>
  <div class="container pb-3">
    <app-breadcrumbs
      [path]="[{link: ['/'], title: 'Home'}, {title: 'Language Keys'}]"></app-breadcrumbs>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <app-side-nav></app-side-nav>
      </div>
      <div class="col-9">
        <div class="row mb-3">
          <div class="col text-right">
            <ng-container *ngIf="translateAllLoading; else translateButtonTpl">
              <button class="btn btn-outline-warning mr-3" disabled>
                Translating...
              </button>
            </ng-container>
            <ng-template #translateButtonTpl>
              <button class="btn btn-outline-warning mr-3" (click)="translateEmpty()">
                Translate empty keys
              </button>
            </ng-template>
            <button class="btn btn-outline-primary" (click)="createKey()">Create new key</button>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body" [formGroup]="form">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="domain">Search for domain</label>
                  <input type="text" id="domain" placeholder="Domain 🍏" class="form-control" formControlName="domain">
                </div>
                <div class="form-group">
                  <label for="language_key">Search for language key</label>
                  <input type="text" id="language_key" placeholder="Language Key 🔑" class="form-control"
                         formControlName="key">
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="translation">Search for translation</label>
                  <input type="text" id="translation" formControlName="translation" class="form-control"
                         placeholder="Translation">
                </div>
              </div>
            </div>
          </div>
        </div>


        <ng-container *ngIf="keys$ | async as keys">
          <ng-container *ngIf="filtered$ | async as filtered">
            <div class="card mb-3" app-translation-row *ngFor="let key of filtered; let i = index" [key]="key"></div>

            <ngb-pagination [collectionSize]="keys.length" [pageSize]="pageLimit" (pageChange)="pageChanged($event)"
                            [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>
          </ng-container>
        </ng-container>

      </div>
    </div>
  </div>

</ng-container>
