import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, first, map, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {TokenService} from '../../services/token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshInProgress = false;
  private refreshSubject: Subject<boolean> = new Subject<boolean>();

  constructor(private authService: AuthService,
              private tokenService: TokenService,
              private http: HttpClient,
              private router: Router,
  ) {
  }

  public intercept(req: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('token')) {
      return delegate.handle(req);
    }

    return this.processIntercept(req, delegate);
  }

  private processIntercept(original: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
    const clone: HttpRequest<any> = original.clone();

    return this.request(clone).pipe(
      switchMap((req: HttpRequest<any>) => delegate.handle(req)),
      catchError((res: HttpErrorResponse) => this.responseError(clone, res))
    );
  }

  private request(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (this.refreshInProgress) {
      return this.delayRequest(req);
    }

    return this.addToken(req);
  }

  private addToken(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.tokenService.getAccessToken().pipe(
      map((token: string) => {
        if (token) {
          const setHeaders = {Authorization: `Bearer ${token}`};
          return req.clone({setHeaders});
        }
        return req;
      }),
      first()
    );
  }

  private delayRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.refreshSubject.pipe(
      first(),
      switchMap((status: boolean) => status ? this.addToken(req) : throwError(req))
    );
  }

  private responseError(req: HttpRequest<any>, res: HttpErrorResponse): Observable<HttpEvent<any>> {
    const refreshShouldHappen: boolean = res.status === 401;

    if (refreshShouldHappen && !this.refreshInProgress) {
      this.refreshInProgress = true;

      this.authService.refreshToken().pipe(
        take(1)
      ).subscribe(() => {
          this.refreshInProgress = false;
          this.refreshSubject.next(true);
        },
        () => {
          this.refreshInProgress = false;
          this.refreshSubject.next(false);
        }
      );
    }

    if (refreshShouldHappen && this.refreshInProgress) {
      return this.retryRequest(req, res);
    }
    return throwError(res);
  }

  private retryRequest(req: HttpRequest<any>, res: HttpErrorResponse): Observable<HttpEvent<any>> {
    return this.refreshSubject.pipe(
      first(),
      switchMap((status: boolean) => status ? this.http.request(req) : throwError(res || req))
    );
  }
}
