import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {map, tap} from 'rxjs/operators';
import {TokenService} from '../../services/token.service';


@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {

  private readonly LS_ACCESS_TOKEN_KEY = 'access_token';

  constructor(
    private router: Router,
    protected localStorage: LocalStorage,
    private tokenService: TokenService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkPermission();
  }

  private checkPermission(): Observable<boolean> {
    return this.tokenService.getAccessToken().pipe(
      map(data => !data),
      tap(data => {
        if (!data) {
          this.router.navigate(['/']).then();
        }
      }),
    );
  }
}
