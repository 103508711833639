<div class="modal-header">
  <h4 class="modal-title">Update language: Danish</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-row" [formGroup]="form">
    <div class="col-12 mb-3">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="enabled" formControlName="enabled">
        <label class="form-check-label" for="enabled">Enabled</label>
      </div>
    </div>

    <div class="col-12 mb-3">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="default_language" formControlName="default">
        <label class="form-check-label" for="default_language">Default language</label>
      </div>
    </div>

    <div class="col-12 mb-3">
      <label for="languageCode">Language code. For example (en) see <a target="_blank" href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes">ISO_639-1</a></label>
      <input type="text" class="form-control" id="languageCode" placeholder="Language code" formControlName="languageCode">
      <div class="invalid-feedback">
        Please provide a valid language code.
      </div>
    </div>

    <div class="col-12 mb-3">
      <label for="languageId">Language ID for example (en-EN) see <a target="_blank" href="http://www.lingoes.net/en/translator/langcode.htm">ISO 3166-1</a></label>
      <input type="text" class="form-control" id="languageId" placeholder="Language ID" formControlName="languageId">
      <div class="invalid-feedback">
        Please provide a valid language ID.
      </div>
    </div>

    <div class="col-12 mb-3">
      <label for="languageName">Language name</label>
      <input type="text" class="form-control" id="languageName" placeholder="Language name" formControlName="languageName">
      <div class="invalid-feedback">
        Please provide a valid language code.
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  <button type="button" class="btn btn-outline-success" (click)="submitForm()" [disabled]="form.invalid">Save</button>
</div>
