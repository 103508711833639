<div class="card-body" [formGroup]="form">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <input type="text" readonly class="form-control" formControlName="domain">
      </div>
      <div class="form-group">
        <input type="text" readonly class="form-control" formControlName="key">
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <button class="btn btn-outline-danger btn-block" (click)="deleteKey()">Delete</button>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <button class="btn btn-outline-warning btn-block" (click)="editKey()">Edit</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div *ngFor="let translation of translations$ | async">
        <app-translation [translation]="translation" [languageKey]="key"></app-translation>
      </div>
    </div>
  </div>
</div>
