import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LanguagesComponent} from './languages/languages.component';
import {LanguageComponent} from './language/language.component';
import {HomeComponent} from './home/home.component';
import {SharedModule} from './shared/shared.module';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {LanguageKeysComponent} from './language-keys/language-keys.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {DefaultDataServiceConfig, EntityDataModule, EntityMetadataMap} from '@ngrx/data';
import {LanguageModel} from './models/language.model';
import {LanguageKeyModel} from './models/language-key.model';
import {TranslationModel} from './models/translation.model';
import {AuthModule} from './auth/auth.module';
import {TokenService} from './services/token.service';


export function selectLanguageId(event: LanguageModel): string {
  return event.id.toString();
}

export function selectLanguageKeyId(event: LanguageKeyModel): string {
  return event.id.toString();
}

export function selectTranslationId(event: TranslationModel): string {
  return event.id.toString();
}

export function sortById(a: { id: number }, b: { id: number }): number {
  return b.id - a.id;
}

const entityMetadata: EntityMetadataMap = {
  language: {
    selectId: selectLanguageId
  },
  languageKey: {
    selectId: selectLanguageKeyId,
    sortComparer: sortById
  },
  translation: {
    selectId: selectTranslationId,
  }
};

const pluralNames = {
  language: 'languages',
  languageKey: 'languageKeys',
  translation: 'translations',
};

export const entityConfig = {
  entityMetadata,
  pluralNames
};

const defaultDataServiceConfig: DefaultDataServiceConfig = {
  entityHttpResourceUrls: {
    language: {
      entityResourceUrl: environment.apiDomain + '/language/',
      collectionResourceUrl: environment.apiDomain + '/language/',
    },
    languageKey: {
      entityResourceUrl: environment.apiDomain + '/language-key/',
      collectionResourceUrl: environment.apiDomain + '/language-key/'
    },
    translation: {
      entityResourceUrl: environment.apiDomain + '/translation/',
      collectionResourceUrl: environment.apiDomain + '/translation/'
    },
  },
};


@NgModule({
  declarations: [
    AppComponent,
    LanguagesComponent,
    LanguageComponent,
    HomeComponent,
    LanguageKeysComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    ReactiveFormsModule,
    HttpClientModule,
    AuthModule,

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
  ],
  providers: [
    {provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
    TokenService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
