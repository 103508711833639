import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LanguagesComponent} from './languages/languages.component';
import {HomeComponent} from './home/home.component';
import {LanguageKeysComponent} from './language-keys/language-keys.component';
import {LoginComponent} from './auth/components/login/login.component';
import {GuestGuard} from './auth/guards/guest.guard';
import {AuthGuard} from './auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard]
  },
  {
    path: 'auth', component: LoginComponent, canActivate: [GuestGuard]
  },
  {
    path: 'languages', component: LanguagesComponent, canActivate: [AuthGuard]
  },
  {
    path: 'language-keys', component: LanguageKeysComponent, canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
