import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, combineLatest, from, Observable, of, Subscription} from 'rxjs';
import {LanguageKeyService} from '../services/language-key.service';
import {LanguageKeyModel} from '../models/language-key.model';
import {LanguageService} from '../services/language.service';
import {TranslationModalComponent} from '../shared/translation-modal/translation-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {catchError, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {TranslationService} from '../services/translation.service';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {LanguageModel} from '../models/language.model';
import {TranslationModel} from '../models/translation.model';

@Component({
  selector: 'app-language-keys',
  templateUrl: './language-keys.component.html',
  styleUrls: ['./language-keys.component.scss']
})
export class LanguageKeysComponent implements OnInit, OnDestroy {
  keys$: Observable<LanguageKeyModel[]>;
  filtered$: Observable<LanguageKeyModel[]>;
  languages$: Observable<LanguageModel[]>;
  translations$: Observable<TranslationModel[]>;

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  subscription = new Subscription();
  translateAllLoading = false;
  form: FormGroup;
  pageLimit = 10;

  constructor(
    private activatedRoute: ActivatedRoute,
    private languageKeyService: LanguageKeyService,
    private languageService: LanguageService,
    private translationService: TranslationService,
    private modal: NgbModal,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      domain: this.fb.control(''),
      key: this.fb.control(''),
      translation: this.fb.control('')
    });

    this.keys$ = this.languageKeyService.entities$;
    this.languages$ = this.languageService.entities$;
    this.translations$ = this.translationService.entities$;

    this.filtered$ = combineLatest([this.keys$, this.translations$, this.form.valueChanges.pipe(
      startWith(null),
    ), this.page$]).pipe(
      map(([keys, translations, form, page]) => {
        let filtered = keys;

        if (!form) {
          return this.paginateItems(page, filtered);
        }

        if (form.domain) {
          filtered = filtered.filter(key => key.domain.toLowerCase().indexOf(form.domain) !== -1);
        }

        if (form.key) {
          filtered = filtered.filter(key => key.key.toLowerCase().indexOf(form.key) !== -1);
        }

        // find language key ids in translations
        if (form.translation) {
          const filteredTranslations = translations.filter(
            translation => translation.translation ? translation.translation.toLowerCase().indexOf(form.translation) !== -1 : false
          );
          filtered = filtered.filter(key => filteredTranslations.some(translation => translation.key === key.id));
        }
        return this.paginateItems(page, filtered);
      }),
      // map((keys) => keys.slice(0, 10))
    );
  }

  private paginateItems(page: number, filtered: LanguageKeyModel[]): LanguageKeyModel[] {
    const startOffset = (page - 1) * this.pageLimit;
    const endOffset = startOffset + this.pageLimit;
    return filtered.slice(startOffset, endOffset);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createKey(): void {
    const modalRef = this.modal.open(TranslationModalComponent);

    if (localStorage.getItem('domain') && localStorage.getItem('key')) {
      modalRef.componentInstance.languageKey = {
        domain: localStorage.getItem('domain'),
        key: localStorage.getItem('key'),
      };
    }

    this.subscription.add(
      from(modalRef.result).pipe(
        catchError(() => of(null)),
        filter(form => !!form),
        tap((form) => {
          localStorage.setItem('domain', form.domain);
          localStorage.setItem('key', form.key);
        }),
        switchMap((form) => this.languageKeyService.upsert(form))
      ).subscribe(() => {
        this.translationService.load();
      })
    );
  }

  translateEmpty(): void {
    this.translateAllLoading = true;
    this.subscription.add(
      this.translationService.translateAll().pipe(
        filter(res => !!res.success)
      ).subscribe(() => {
        this.translateAllLoading = false;
        this.translationService.load();
      })
    );
  }

  pageChanged(page): void {
    this.page$.next(page);
  }

  get languages(): FormArray {
    return this.form.get('languages') as FormArray;
  }

}
