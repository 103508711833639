import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import {RouterModule} from '@angular/router';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { LanguageModalComponent } from './language-modal/language-modal.component';
import { TranslationModalComponent } from './translation-modal/translation-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import { TranslationRowComponent } from './translation-row/translation-row.component';
import { TranslationComponent } from './translation/translation.component';
import { HeaderComponent } from './header/header.component';



@NgModule({
  declarations: [
    SideNavComponent,
    BreadcrumbsComponent,
    LanguageModalComponent,
    TranslationModalComponent,
    TranslationRowComponent,
    TranslationComponent,
    HeaderComponent
  ],
    exports: [
        SideNavComponent,
        BreadcrumbsComponent,
        TranslationRowComponent,
        HeaderComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule
    ]
})
export class SharedModule { }
