import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslationModel} from '../../models/translation.model';
import {LanguageKeyModel} from '../../models/language-key.model';

@Component({
  selector: 'app-translation-modal',
  templateUrl: './translation-modal.component.html',
  styleUrls: ['./translation-modal.component.scss']
})
export class TranslationModalComponent implements OnInit {
  @Input() languageKey: LanguageKeyModel;

  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      domain: this.fb.control(this.languageKey?.domain),
      key: this.fb.control(this.languageKey?.key),
    });
  }

  saveForm(): void {
    this.activeModal.close(this.form.value);
  }
}
