import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LanguageService} from '../../../services/language.service';
import {LanguageKeyService} from '../../../services/language-key.service';
import {TranslationService} from '../../../services/translation.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private languageService: LanguageService,
    private languageKeyService: LanguageKeyService,
    private translationService: TranslationService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      username: this.fb.control('', [Validators.required, Validators.email]),
      password: this.fb.control('', [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submitForm(): void {
    this.subscription.add(
      this.auth.login(this.form.value).pipe(
      ).subscribe(result => {
        this.router.navigate(['/']).then();
        this.languageService.load();
        this.languageKeyService.load();
        this.translationService.load();
      }, (error: HttpErrorResponse) => {
        this.form.get('username').setErrors({invalid: true});
        this.form.get('username').markAsTouched();
      })
    );
  }

  get username(): FormControl {
    return this.form.get('username') as FormControl;
  }

  get password(): FormControl {
    return this.form.get('password') as FormControl;
  }
}
