<div class="modal-header">
  <h4 class="modal-title">Create/update translation key</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-row" [formGroup]="form">
    <div class="col-12 mb-3">
      <label for="code">Domain</label>
      <input type="text" class="form-control" id="code" formControlName="domain" placeholder="Domain">
      <div class="invalid-feedback">
        Please provide a valid domain
      </div>
    </div>

    <div class="col-12 mb-3">
      <label for="key">Language Key</label>
      <input type="text" class="form-control" id="key" formControlName="key" placeholder="Language Key">
      <div class="invalid-feedback">
        Please provide a valid Language Key
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Close</button>
  <button type="button" class="btn btn-outline-success" (click)="saveForm()">Save</button>
</div>
