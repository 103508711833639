import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import {AbstractUnsubscriber} from '../../core/abstract-unsubscriber';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AbstractUnsubscriber implements OnInit {
  user$: Observable<any>;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user$ = this.authService.user$;

  }

  logout(): void {
    this.subscription.add(
      this.authService.logout().subscribe(() => {
        this.router.navigate(['/auth']).then();
      })
    );
  }

}
