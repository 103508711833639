import {Component, Input, OnInit} from '@angular/core';
import {TranslationModel} from '../../models/translation.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslationService} from '../../services/translation.service';
import {LanguageKeyModel} from '../../models/language-key.model';
import {LanguageModel} from '../../models/language.model';
import {Observable} from 'rxjs';
import {LanguageService} from '../../services/language.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
  @Input() translation: TranslationModel;
  @Input() languageKey: LanguageKeyModel;

  loading = false;
  translating = false;
  form: FormGroup;

  language$: Observable<LanguageModel>;

  constructor(
    private fb: FormBuilder,
    private translationService: TranslationService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      translation: this.fb.control(this.translation?.translation),
    });

    this.language$ = this.languageService.entities$.pipe(
      map(languages => languages.find(l => l.id === this.translation.language))
    );
  }

  saveForm(shouldTranslate?: boolean): void {
    if (shouldTranslate) {
      this.translating = true;
    } else {
      this.loading = true;
    }
    this.translationService.update({
      ...this.form.value,
      id: this.translation.id,
      key: this.languageKey.id,
      language: this.translation.language,
      shouldTranslate,
    }).subscribe(result => {
      this.loading = false;
      this.translating = false;
      this.translationService.load();
    });
  }
}
