<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        *ngFor="let item of path">
      <ng-container *ngIf="item.link; else noLinkItemTpl">
        <a [routerLink]="item.link">{{item.title}}</a>
      </ng-container>
      <ng-template #noLinkItemTpl>
        {{item.title}}
      </ng-template>
    </li>
  </ol>
</nav>
