import {Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {LocalStorage} from '@ngx-pwa/local-storage';

@Injectable()
export class TokenService {

  private readonly LS_ACCESS_TOKEN_KEY = 'access_token';
  private readonly LS_REFRESH_TOKEN_KEY = 'refresh_token';

  constructor(
    private localStorage: LocalStorage,
  ) {
  }

  getAccessToken(): Observable<any> {
    return this.localStorage.getItem(this.LS_ACCESS_TOKEN_KEY);
  }

  getRefreshToken(): Observable<any> {
    return this.localStorage.getItem(this.LS_REFRESH_TOKEN_KEY);
  }

  setAccessToken(token: string): Observable<any> {
    return this.localStorage.setItem(this.LS_ACCESS_TOKEN_KEY, token);
  }

  setRefreshToken(token: string): Observable<any> {
    return this.localStorage.setItem(this.LS_REFRESH_TOKEN_KEY, token);
  }

  clear(): Observable<any> {
    return combineLatest([
      this.localStorage.removeItem(this.LS_ACCESS_TOKEN_KEY),
      this.localStorage.removeItem(this.LS_REFRESH_TOKEN_KEY)
    ]);
  }

}
