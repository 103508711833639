<ng-container *ngIf="languageKeys$ | async as languageKeys">
  <div class="container pt-5 pb-3">
    <app-header></app-header>
  </div>
  <div class="container-fluid pb-3">
    <app-breadcrumbs [path]="[{link: ['/'], title: 'Home'}, {link: ['/languages'], title: 'Languages'}, {title: 'da-DK'}]"></app-breadcrumbs>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3">
        <app-side-nav></app-side-nav>
      </div>
      <div class="col-9">
        <div class="row mb-3">
          <div class="col text-right">
            <button class="btn btn-outline-primary" (click)="createKey()">Create new key</button>
          </div>
        </div>
        <table class="table table-bordered">
          <thead class="">
            <tr>
              <th>Domain</th>
              <th>Language key</th>
              <th>Translation</th>
              <th>Actions</th>
            </tr>
            <tr [formGroup]="searchForm">
              <th>
                <input type="text" class="form-control" placeholder="Domain" formControlName="domain">
              </th>
              <th>
                <input type="text" class="form-control" placeholder="Language Key" formControlName="key">
              </th>
              <th>
                <input type="text" class="form-control" placeholder="Translation" formControlName="translation">
              </th>
              <th>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr app-translation-row *ngFor="let languageKey of languageKeys; let i = index; trackBy: trackByFn" (cloneEvent)="cloneTranslation($event)"></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</ng-container>
