import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LanguageModel} from '../../models/language.model';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {
  @Input() data: LanguageModel;
  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      enabled: this.fb.control(this.data?.enabled),
      default: this.fb.control(this.data?.default),
      languageCode: this.fb.control(this.data?.languageCode, [Validators.required, Validators.minLength(1)]),
      languageId: this.fb.control(this.data?.languageId, [Validators.required, Validators.minLength(1)]),
      languageName: this.fb.control(this.data?.languageName, [Validators.required, Validators.minLength(1)]),
    });
  }

  submitForm(): void {
    this.activeModal.close(this.form.value);
  }
}
