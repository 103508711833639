import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslationService} from '../../services/translation.service';
import {from, Observable, of, Subscription} from 'rxjs';
import {LanguageKeyModel} from '../../models/language-key.model';
import {TranslationModalComponent} from '../translation-modal/translation-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LanguageKeyService} from '../../services/language-key.service';
import {TranslationModel} from '../../models/translation.model';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {LanguageService} from '../../services/language.service';
import {LanguageModel} from '../../models/language.model';
import {AbstractUnsubscriber} from '../../core/abstract-unsubscriber';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-translation-row]',
  templateUrl: './translation-row.component.html',
  styleUrls: ['./translation-row.component.scss']
})
export class TranslationRowComponent extends AbstractUnsubscriber implements OnInit, OnDestroy {
  @Input() key: LanguageKeyModel;
  @Output() cloneEvent = new EventEmitter();

  form: FormGroup;
  subscription = new Subscription();

  translations$: Observable<TranslationModel[]>;
  language$: Observable<LanguageModel>;

  constructor(
    private fb: FormBuilder,
    private modal: NgbModal,
    private languageKeyService: LanguageKeyService,
    private translationService: TranslationService,
    private languageService: LanguageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.translations$ = this.translationService.entities$.pipe(
      map(translations => {
        return translations.filter((translation: TranslationModel) => translation.key === this.key.id);
      })
    );

    this.form = this.fb.group({
      domain: this.fb.control(this.key?.domain),
      key: this.fb.control(this.key?.key),
    });
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clone(): void {
    // this.cloneEvent.emit(this.translation);
  }

  clear(): void {
    // this.form.get('translation').reset();
  }

  editKey(): void {
    const modalRef = this.modal.open(TranslationModalComponent);
    modalRef.componentInstance.languageKey = this.key;

    this.subscription.add(
      from(modalRef.result).pipe(
        catchError(() => of(null)),
        filter(form => !!form),
        switchMap((form: LanguageKeyModel) => {
          return from(this.languageKeyService.update({...form, id: this.key.id}));
        })
      ).subscribe()
    );
  }

  deleteKey(): void {
    if (confirm('Are you sure that you want to delete this language key?')) {
      this.subscription.add(
        this.languageKeyService.delete(this.key).subscribe()
      );
    }
  }
}
