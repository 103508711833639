import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslationModalComponent} from '../shared/translation-modal/translation-modal.component';
import {combineLatest, from, Observable, of, Subscription} from 'rxjs';
import {TranslationModel} from '../models/translation.model';
import {TranslationService} from '../services/translation.service';
import {ActivatedRoute} from '@angular/router';
import {catchError, filter, startWith, switchMap} from 'rxjs/operators';
import {LanguageService} from '../services/language.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LanguageKeyModel} from '../models/language-key.model';
import {LanguageKeyService} from '../services/language-key.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  languageKeys$: Observable<LanguageKeyModel[]>;

  subscription = new Subscription();
  searchForm: FormGroup;

  constructor(
    private modal: NgbModal,
    private translationService: TranslationService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private languageKeyService: LanguageKeyService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.languageKeys$ = this.languageKeyService.entities$;
  }

  private initLanguages(): void {
    // this.language$ = this.activatedRoute.params.pipe(
    //   switchMap(route => this.languageService.find(route.slug)),
    // );
  }

  private initTranslations(): void {
    // this.translations$ = this.activatedRoute.params.pipe(
    //   switchMap(route => combineLatest([
    //     this.searchForm.valueChanges.pipe(
    //       startWith(this.searchForm.value as object)
    //     ),
    //     this.translationService.all(route.slug)
    //   ]).pipe(
    //     // map(([search, translations]) => {
    //     //   if (!search.key.length && !search.translation.length) {
    //     //     return translations;
    //     //   }
    //     //
    //     //   return translations.filter(t =>
    //     //     t.key.indexOf(search.key) !== -1 &&
    //     //     t.translation.indexOf(search.translation) !== -1
    //     //   );
    //     // }),
    //     // map(translations => translations.slice(0, 10))
    //   ))
    // );
  }

  private initSearchForm(): void {
    this.searchForm = this.fb.group({
      domain: this.fb.control(''),
      key: this.fb.control(''),
      translation: this.fb.control(''),
    });
  }

  createKey(event?: TranslationModel): void {
    const modalRef = this.modal.open(TranslationModalComponent);

    if (event) {
      modalRef.componentInstance.translation = event;
    }

    // this.subscription.add(
    //   from(modalRef.result).pipe(
    //     catchError(() => of(null)),
    //     filter(form => !!form),
    //     switchMap((form: TranslationModel) => {
    //       return from(this.translationService.create({...form, language: this.activatedRoute.snapshot.params.slug}));
    //     })
    //   ).subscribe()
    // );
  }

  cloneTranslation(translation: TranslationModel): void {
    const modalRef = this.modal.open(TranslationModalComponent);
    modalRef.componentInstance.translation = translation;

    // this.subscription.add(
    //   from(modalRef.result).pipe(
    //     catchError(() => of(null)),
    //     filter(form => !!form),
    //     switchMap((form: TranslationModel) => {
    //       return from(this.translationService.create({...form, language: this.activatedRoute.snapshot.params.slug}));
    //     })
    //   ).subscribe()
    // );
  }

  trackByFn(index: number, item: any): any {
    return item.id;
  }
}
