<div class="container pt-5 pb-3">
  <app-header></app-header>
</div>
<div class="container pb-3">
  <app-breadcrumbs [path]="[{title: 'Home'}]"></app-breadcrumbs>
</div>
<div class="container">
  <div class="row">
    <div class="col-3">
      <app-side-nav></app-side-nav>
    </div>
    <div class="col-9 ">
      <div class="card text-center">
        <div class="card-body d-flex align-items-center justify-content-center" style="height: 500px">
          <h1>Welcome to Lentii Backoffice panel</h1>
        </div>
      </div>
    </div>
  </div>
</div>
