import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {TranslationModel} from '../models/translation.model';
import {HttpClient} from '@angular/common/http';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService extends EntityCollectionServiceBase<TranslationModel> {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    protected serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    super('translation', serviceElementsFactory);
  }

  translateAll(): Observable<any> {
    return this.http.post(environment.apiDomain + '/translation/translate-all', {});
  }
}
