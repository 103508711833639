<div class="container">
  <div class="row">
    <div class="col-12 col-md-6 offset-md-3 d-flex align-items-center" style="min-height: 100vh">
      <form class="d-block w-100" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="form-group">
          <label for="exampleInputEmail1">Email address</label>
          <input type="email" class="form-control" [class.is-invalid]="username.invalid && username.touched"
                 id="exampleInputEmail1" formControlName="username">
          <div class="invalid-feedback">
            <p *ngIf="username.hasError('required')">This field is required</p>
            <p *ngIf="username.hasError('invalid')">Invalid username/password combination</p>
            <p *ngIf="username.hasError('email')">Please, provide valid email address</p>
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input type="password" class="form-control" [class.is-invalid]="password.invalid && password.touched"
                 formControlName="password" id="exampleInputPassword1">
          <div class="invalid-feedback">
            <p *ngIf="password.hasError('required')">This field is required</p>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="form.invalid">Submit</button>
      </form>
    </div>
  </div>
</div>
