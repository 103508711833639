import {Component, OnInit} from '@angular/core';
import {LanguageService} from './services/language.service';
import {LanguageKeyService} from './services/language-key.service';
import {TranslationService} from './services/translation.service';
import {AuthService} from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private languageService: LanguageService,
    private languageKeyService: LanguageKeyService,
    private translationService: TranslationService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.authService.syncUser().subscribe(() => {
      this.fetchEntities();
    });
  }

  private fetchEntities(): void {
    this.languageService.load();
    this.languageKeyService.load();
    this.translationService.load();
  }
}
