import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LanguageModalComponent} from '../shared/language-modal/language-modal.component';
import {from, Observable, of, Subscription} from 'rxjs';
import {LanguageModel} from '../models/language.model';
import {LanguageService} from '../services/language.service';
import {catchError, filter, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  languages$: Observable<LanguageModel[]>;

  subscription = new Subscription();

  constructor(
    private modal: NgbModal,
    private languageService: LanguageService,
  ) {
    this.initLanguages();
  }


  ngOnInit(): void {
  }

  editLanguage(language: LanguageModel): void {
    const modalRef = this.modal.open(LanguageModalComponent);
    modalRef.componentInstance.data = language;

    this.subscription.add(
      from(modalRef.result).pipe(
        catchError(() => of(null)),
        filter(form => !!form),
        switchMap(form => this.languageService.update({...form, id: language.id}))
      ).subscribe((form) => {
        this.languageService.load();
      })
    );
  }

  createLanguage(): void {
    const modalRef = this.modal.open(LanguageModalComponent);
    modalRef.componentInstance.data = {
      enabled: true,
    };

    this.subscription.add(
      from(modalRef.result).pipe(
        catchError(() => of(null)),
        filter(form => !!form),
        // switchMap(form => this.languageService.create(form))
      ).subscribe((form) => {
        this.languageService.upsert(form);
      })
    );
  }

  deleteLanguage(language: LanguageModel): void {
    if (confirm('Do you really want to delete this language?')) {
      this.languageService.delete(language.id);
    }
    // this.subscription.add(
    //   this.languageService.delete(language.languageCode).subscribe()
    // );
  }

  private initLanguages(): void {
    this.languages$ = this.languageService.entities$;
  }
}
