// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiDomain: 'https://api.lentii.com',
  firebaseConfig: {
    apiKey: 'AIzaSyCkOFn479lX-d4Bl7QzcTbRy5RN9OBVH2o',
    authDomain: 'lentii-backoffice.firebaseapp.com',
    projectId: 'lentii-backoffice',
    storageBucket: 'lentii-backoffice.appspot.com',
    messagingSenderId: '242544988689',
    appId: '1:242544988689:web:3b8756acf106d7ce1897b0'
  },
  auth: {
    clientId: '1_3qsfdhman92c84g0scg08844k0oo0gs8g4osk804kco400gkg4',
    clientSecret: '47iyqi5rdvwg40w0okco4044skw0sswow4ogkgc844o8cg8k40',
    grantType: 'password',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
